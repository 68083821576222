import React from 'react'

import type {TabsProps} from 'antd'
import DoSpaceToExcel from '@/pages/Tools/components/do-space-to-excel'
import {Tabs} from 'antd'

type Props = {};

const items: TabsProps['items'] = [
	{
		key:      'do-space-to-excel',
		label:    `DO Space To Excel`,
		children: <DoSpaceToExcel/>,
	},
]

const ToolsPage = (props: Props) => {
	return (<>
		<Tabs defaultActiveKey={'do-space-to-excel'} items={items}/>
	</>)
}

export default ToolsPage
