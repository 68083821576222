import ApiService from '@/services/api.service';
import { Category, ContentHub, LimitCategory, Node } from '@/types';
import { getFilterStorage, setFilterStorage } from '@/util/storage';
import {
  Col,
  Row,
  Skeleton,
  Spin,
  Badge,
  Button,
  Modal,
  Checkbox,
  Radio,
  RadioChangeEvent,
  Collapse,
  CollapseProps,
} from 'antd';
import type { DataNode } from 'antd/lib/tree';
import React, { useEffect, useRef, useState } from 'react';
import EditTableCell from '../Edit/components/edit-table-cell';
import { getList, updateContentHub } from '../Edit/services/edit-database';
import { Portal } from '../Portal/types';
import CardViewList from './components/CardView/card-view-list';
import Filters from './components/filters';
import HeaderButtons from './components/header-buttons';
import ListView from './components/ListView/list-view';
import { pushToWP } from './services/wordpress.services';
import { DataPush } from './types';
import PushSettings from './components/push-settings';
import jwtService from '@/services/jwt.service';
import { createCart, findCart, patchCart } from './services/cart.service';
import styled from 'styled-components';
import CartModal from './components/cart-modal';
import { getContents } from '@/services/content-hub-services';
import { getList as getListPortal } from '../Portal/services/portal.services';
import { Company as CompanyType } from '../Company/types';
import { getList as getListCompany } from '../Company/services/company.services';
import axios from 'axios';
import * as Excel from 'exceljs';
import { getCurrentDateTime } from '@/util/date-time';

const CheckboxGroup = Checkbox.Group;

const CartStyle = styled.div`
  .ant-badge-count {
    margin-top: 10px;
  }
`;

const SelectedTagsStyle = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const ViewAndPush = () => {
  const [toggleView, setToggleView] = useState<{
    type: 'list' | 'table';
  }>({ type: 'table' });
  const filtersRef = useRef<Record<string, any>>({});

  const viewListEvent = () => {
    setToggleView({ type: 'list' });
  };

  const viewTableEvent = () => {
    setToggleView({ type: 'table' });
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [dataList, setDataList] = useState<ContentHub[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [selectedNode, setSelectedNode] = useState<{ [category: string]: string[] }>({});
  const [selectedGender, setSelectedGender] = useState<string[]>([]);
  const [cateList, setCateList] = useState<Category[]>([]);
  const [limitCategories, setLimitCategories] = useState<LimitCategory[]>([]);
  const [maxTimes, setMaxTimes] = useState<number>(0);
  const [portalNames, setPortalNames] = useState<string[]>([]);
  const [genderOptions, setGenderOptions] = useState<String[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedContentHubs, setSelectedContentHubs] = useState<ContentHub[]>([]);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(500);
  const [userId, setUserId] = useState<number>(0);
  const [allowShuffle, setAllowShuffle] = useState<boolean>(false);
  const [portalList, setPortalList] = useState<Portal[]>([]);
  const [companyList, setCompanyList] = useState<CompanyType[]>([]);
  const [listSelectedTags, setListSelectedTags] = useState<
    { value: [string, string]; displayText: string }[]
  >([]);
  const [checkedSelectedTags, setCheckedSelectedTags] = useState<string[]>([]);
  const currentFetchData = useRef<ContentHub[]>([]);
  const [selectCheckTagsCondition, setSelectCheckTagsCondition] = useState<'AND' | 'OR'>('OR');

  useEffect(() => {
    getListPortal().then((res) => {
      setPortalList(res);
    });
    getListCompany().then((res) => setCompanyList(res));
  }, []);

  const callBack = (
    newSelectedNode,
    newSelectedGender,
    newLimitCategories,
    newMaxTimes = 0,
    newPortalNames: string[] = [],
    newCompanyIds = [],
    isMounted?: boolean
  ) => {
    let companyPortalList: Portal[] = [];
    newCompanyIds.forEach((companyId) => {
      let temp = portalList.filter((e) => e.companyId === companyId);
      companyPortalList = [...companyPortalList, ...temp];
    });
    let companyPortalNames = companyPortalList.map((e) => (e.name ? e.name : ''));

    if (newPortalNames && newPortalNames.length) {
      newPortalNames = [...new Set([...newPortalNames, ...companyPortalNames])]; // remove duplicate element
      newPortalNames = newPortalNames.map((e) => {
        // remove '/' character in portal url
        const last = e.charAt(e.length - 1);
        if (last === '/') return e.slice(0, -1);
        return e;
      });
    } else if (companyPortalNames && companyPortalNames.length) {
      newPortalNames = [...companyPortalNames];
      newPortalNames = newPortalNames.map((e) => {
        // remove '/' character in portal url
        const last = e.charAt(e.length - 1);
        if (last === '/') return e.slice(0, -1);
        return e;
      });
    }

    if (
      selectedNode !== newSelectedNode ||
      newSelectedGender !== selectedGender ||
      newLimitCategories !== limitCategories ||
      newMaxTimes !== maxTimes ||
      newPortalNames !== portalNames
    ) {
      setLoadingData(true);
      setSelectedNode(newSelectedNode);
      setSelectedGender(newSelectedGender);
      setLimitCategories(newLimitCategories);
      setMaxTimes(newMaxTimes);
      setPortalNames(newPortalNames);
      if (!isMounted) {
        filtersRef.current = {
          ...filtersRef.current,
          pageSize: filtersRef.current.pageSize || 10,
          newSelectedNode,
          newSelectedGender,
          newLimitCategories,
          maxTimes: newMaxTimes,
          portalNames: newPortalNames,
        };
      }
    }
  };

  const clearFilterEvent = (): void => {
    filtersRef.current = {};
    callBack([], [], {}, 0, [], []);
  };

  const fetchData = async () => {
    const newTreeData: DataNode[] = [];
    const categories = await ApiService.get('/Categories').then((res) => res.data);
    const tagsApi = await ApiService.getWithFilters('/Tags', {
      where: {
        name: { neq: '' },
        slug: { neq: '' },
        count: { gt: 0 },
      },
    }).then((res) => res.data);

    const allTags = tagsApi.filter((tag) => !/^t\d+$/.test(tag.name) && !/^d\d+$/.test(tag.name));

    setCateList(categories);
    let categoryIndex = 0;
    for (const category of categories) {
      let node: Node = {
        title: category.name,
        key: `${categoryIndex}`,
        children: [],
      };
      const tags = allTags.filter((tag) => tag.categoryString === category.slug);
      let tagIndex = 0;
      for (const tag of tags) {
        let childrenNode = {
          title: tag.name,
          key: `${categoryIndex}-${tagIndex}`,
        };
        node.children.push(childrenNode);
        tagIndex++;
      }

      if (!newTreeData.includes(node)) {
        newTreeData.push(node);
      }
      categoryIndex++;
    }

    setTreeData(newTreeData);
    setLoading(false);
  };

  const fetchContentHub = async () => {
    let contentData: any = [];
    if (!selectedNode) {
      return;
    }
    for (const category of Object.keys(selectedNode)) {
      const tags = selectedNode[category];
      let filterData: Record<string, any> = { where: { and: [{ category: category }] } };
      // gender
      if (selectedGender && selectedGender.length) {
        filterData.where.and.push({ gender: { inq: selectedGender } });
      }
      // limit
      if (limitCategories && limitCategories.length) {
        const limitCategory = limitCategories.find((e) => e.name === category);
        if (limitCategory?.limit) {
          filterData.limit = limitCategory.limit;
        }
      }
      // tags
      if (tags.length > 0) {
        let tagFilters: { [key: string]: any } = {};
        if (tags.length === 1 && tags[0] === '') {
          tagFilters.tags = '';
        } else {
          tagFilters = { or: [] };
          for (const tag of tags) {
            tagFilters.or.push({ tags: { ilike: `%${tag}%` } });
          }
        }
        filterData.where.and.push(tagFilters);
      }
      // maxTimes
      if (maxTimes > 0) {
        filterData.where.and.push({ used_count: { lte: maxTimes } });
      }
      // ignore
      if (portalNames.length) {
        for (const portalName of portalNames) {
          filterData.where.and.push({ used: { nilike: `%${portalName}%` } });
        }
      }

      const data = allowShuffle
        ? await getContents(filterData)
        : await ApiService.query('/ContentHubs', filterData).then((res) => res.data);
      contentData = [...contentData, ...data];
    }

    // xóa phần tử trùng trong mảng ( do filter theo tags sẽ có các record bị trùng)
    let resultIds: number[] = [];
    contentData = contentData.filter((element: ContentHub) => {
      if (!resultIds.includes(element.id)) {
        resultIds.push(element.id);
        return element;
      }
    });
    const newTotal = contentData.length;

    // get whether store checked list from storage
    const filtersStorage = getFilterStorage();
    if (filtersStorage?.selectedRowKeys) {
      setSelectedRowKeys(filtersStorage.selectedRowKeys);
    }
    currentFetchData.current = contentData;
    setDataList(contentData);

    initCart(userId);

    setTotal(newTotal);

    const selectedTags: { value: [string, string]; displayText: string }[] = [];

    const tempSelectedTags: string[] = [];
    contentData.forEach((item) => {
      const tagsArray = item.tags?.split(',') || [];
      tagsArray.forEach((tag) => {
        const val = `${item.category}-${tag.trim()}`;
        const remove_t_d_Tags = !/^t\d+$/.test(tag.trim()) && !/^d\d+$/.test(tag.trim());
        if (!tempSelectedTags.includes(val) && remove_t_d_Tags) {
          tempSelectedTags.push(val);
        }
      });
    });

    tempSelectedTags.forEach((item) => {
      const [category, tag] = item.split('-');
      selectedTags.push({ value: [category, tag], displayText: `${category}-${tag}` });
    });

    setListSelectedTags(selectedTags);

    setLoadingData(false);

    checkAllContentsAfterFilter(contentData);
  };

  const onSelectedTagsChange = (list: string[]) => {
    setCheckedSelectedTags(list);
  };

  const filterByTagEvt = () => {
    if (!checkedSelectedTags.length) {
      setDataList(currentFetchData.current);
      const dataIds = currentFetchData.current.map((item) => item.id);
      setSelectedRowKeys(dataIds);
      return;
    }
    const listCategory: string[] = [];
    const listTag: string[] = [];

    checkedSelectedTags.forEach((item) => {
      const [category, tag] = item.split('-');
      listCategory.push(category);
      listTag.push(tag);
    });

    let tempData = currentFetchData.current.slice(0);
    tempData = tempData.filter((item) => {
      if (!item.category) {
        return false;
      }
      const checkTags =
        selectCheckTagsCondition === 'AND'
          ? listTag.every((tag) => item.tags?.includes(tag))
          : listTag.some((tag) => item.tags?.includes(tag));
      if (listCategory.includes(item.category) && checkTags) {
        return true;
      }
      return false;
    });
    const tempDataIds = tempData.map((item) => item.id);
    setDataList(tempData);
    setTotal(tempData.length);
    setSelectedRowKeys(tempDataIds);
  };

  const fetchGender = async () => {
    let newGenderOptions: String[] = [];
    const genderList = await ApiService.get('/Genders').then((res) => res.data);
    newGenderOptions = genderList.map((gender) => gender.name);

    setGenderOptions(newGenderOptions);
  };

  const uncheckAllEvent = (): void => {
    setSelectedRowKeys([]);
  };

  const checkAllContentsAfterFilter = (contents: ContentHub[]): void => {
    const keys = contents.map((content) => content.id);
    setSelectedRowKeys(keys);
  };

  const selectAllEvent = (): void => {
    checkAllContentsAfterFilter(dataList);
  };

  const setPaginationEvt = (data: { page: number; pageSize: number }) => {
    filtersRef.current = {
      ...filtersRef.current,
      pageSize: data.pageSize,
    };
    setCurrentPage(data.page);
    setPageSize(data.pageSize);
  };

  const updateTableRowAfterPush = (dataUpdate: ContentHub[] = []) => {
    const listIds = dataUpdate.map((item) => item.id);
    const tempList = dataList.slice(0);
    tempList.forEach((item, index) => {
      if (listIds.includes(item.id)) {
        const indexUpdate = listIds.findIndex((id) => id === item.id);
        dataList[index].used = dataUpdate[indexUpdate].used;
        dataList[index].used_count = dataUpdate[indexUpdate].used_count;
      }
    });
    setDataList(tempList);
  };

  const dataPushEvent = async (data: DataPush, selectedPortal: Portal[]): Promise<void> => {
    console.log('selectedPortal', selectedPortal);
    console.log('data', data);
    // Promise.all(
    //   selectedPortal.map((portal: Portal) => pushToWP(data, portal.url))
    // );
    // let portalString = '';
    // selectedPortal.forEach(portal => {
    //   portalString += portal.name + ',';
    // })

    // const inputData = data.posts.map((post: ContentHub) => {
    //   return {
    //     used_count: post.used_count ? post.used_count += selectedPortal.length : selectedPortal.length,
    //     used: post.used + portalString,
    //     id: post.id,
    //   }
    // })
    // const result = await Promise.all(inputData.map(content => updateContentHub(content)));
    // updateTableRowAfterPush(result);
    // pushSettingVisibleEvent(false);
    console.time('pushToWP');
    const res = await Promise.all(
      selectedPortal.map((portal: Portal) => pushToWP(data, portal.url))
    );
    console.timeEnd('pushToWP');

    console.time('updateContentHub');
    for (const key in res) {
      if ((res[key] as any)?.status === 200) {
        const inputData = data.posts.map((post: ContentHub) => {
          const portalUsed = post.used?.split(',') || [];
          if (selectedPortal[key]?.name && !portalUsed.includes(selectedPortal[key].name!)) {
            return {
              used_count: post.used_count
                ? (post.used_count += selectedPortal.length)
                : selectedPortal.length,
              used: post.used +','+selectedPortal[key].name!,
              id: post.id,
            };
          } else {
            return {
              used_count: post.used_count
                ? (post.used_count += selectedPortal.length)
                : selectedPortal.length,
              id: post.id,
            };
          }
        });
        const result = await Promise.all(inputData.map((content) => updateContentHub(content)));
        updateTableRowAfterPush(result);
      }
    }
    console.timeEnd('updateContentHub');
  };

  const editContentHubEvent = (): void => {
    setShowEditDialog(true);
  };

  const slugify = (str: string) => {
    return String(str)
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  };
  const handleCreateTagsForPost = async (postCat: string, postTags: string) => {
    const categoryString = slugify(postCat);
    let tagArray = postTags.split(',');
    tagArray = tagArray.map((e) => e.trim());
    const tags = await ApiService.getWithFilters('/Tags', {
      where: {
        categoryString,
        name: { neq: '' },
        slug: { neq: '' },
        count: { gt: 0 },
      },
    }).then((res) => res.data);
    for (const tag of tagArray) {
      let check = tags.find((e) => e.slug == slugify(tag));
      if (!check) {
        //create tag
        const tagObj = {
          categoryString,
          count: 1,
          name: tag,
          slug: slugify(tag),
        };
        const result = await axios.post('/Tags', tagObj);
      }
    }
  };

  const showEditDialogEvent = (
    dataUpdated?: ContentHub,
    action?: 'UPDATE' | 'DELETE' | 'SYNC'
  ): void => {
    setShowEditDialog(false);
    if (!dataUpdated) {
      return;
    }

    //xử lý tạo tags khi user thay đổi tags của bài Post
    handleCreateTagsForPost(dataUpdated.category!, dataUpdated.tags!);
    updateContentHub(dataUpdated); //code here
    const findIndex = dataList.findIndex((e) => e.id === dataUpdated.id);
    if (findIndex !== -1) {
      dataList[findIndex] = dataUpdated;
      setDataList([...dataList]);
    }
  };

  const viewDetailEvent = (id: number): void => {
    selectedContentHubs.length = 0;
    const findContent = dataList.find((e) => e.id === id);
    if (!findContent) {
      return;
    }
    selectedContentHubs.push(findContent);
    setShowEditDialog(true);
  };

  const unMountedTrigger = () => {
    if (Object.keys(filtersRef.current).length > 0) {
      setFilterStorage(filtersRef.current);
    }
  };

  const mountedTrigger = () => {
    const filtersStorage = getFilterStorage();
    if (!Object.keys(filtersStorage).length) {
      return;
    }
    const { pageSize, newSelectedNode, newSelectedGender, newLimitCategories, maxTimes } =
      filtersStorage;
    const isMounted: boolean = true;
    callBack(newSelectedNode, newSelectedGender, newLimitCategories, maxTimes, [], [], isMounted);
  };

  const treeStateChangeEvent = (data: { checkedKeys?: string[]; expandedKeys?: string[] }) => {
    if (data.checkedKeys) {
      filtersRef.current = {
        ...filtersRef.current,
        checkedKeys: data.checkedKeys,
      };
    }
    if (data.expandedKeys) {
      filtersRef.current = {
        ...filtersRef.current,
        expandedKeys: data.expandedKeys,
      };
    }
  };

  const parentCategoriesCheckedStateEvt = (data: {
    noTagsParents?: string[];
    noTagsParentsChecked?: string[];
  }) => {
    if (data.noTagsParents) {
      filtersRef.current = {
        ...filtersRef.current,
        noTagsParents: data.noTagsParents,
      };
    }
    if (data.noTagsParentsChecked) {
      filtersRef.current = {
        ...filtersRef.current,
        noTagsParentsChecked: data.noTagsParentsChecked,
      };
    }
  };

  const handleSelectedRowKeys = (keys: number[]) => {
    setSelectedRowKeys(keys);
  };

  // cart handle
  const [cartIds, setCartIds] = useState<number[]>([]);
  const [cartItems, setCartItems] = useState<ContentHub[]>([]);
  const [isCartModalOpen, setCartIsModalOpen] = useState<boolean>(false);
  const [showPushSetting, setShowPushSetting] = useState<boolean>(false);
  const [currentPageCart, setCurrentPageCart] = useState<number>(1);
  const [selectedRowKeysCart, setSelectedRowKeysCart] = useState<number[]>([]);
  const [cartToPush, setCartToPush] = useState<ContentHub[]>([]);
  const [cartId, setCartId] = useState<number>(0);

  const handleAddToCard = async (items: number[]) => {
    setCartIds(items);
    if (cartId) {
      const cartUpdated = await patchCart(items, cartId);
      return;
    }
    const cartCreate = await createCart({ userId, items: JSON.stringify(items) });
  };

  const initCart = async (_userId: number) => {
    try {
      const cartExist = await findCart({ where: { userId: _userId } });
      if (!cartExist.id) {
        return;
      }
      setCartId(cartExist.id);
      const items = JSON.parse(cartExist.items || '[]');
      let newItems = [];
      if (items.length > 99) {
        newItems = items.slice(0, 100);
      }
      setCartIds(newItems);
    } catch (error) {
      // throw new Error(error);
    }
  };

  const addToCartEvt = (): void => {
    const temp = [...cartIds, ...selectedRowKeys];
    const cartSelect = [...new Set(temp)];
    setSelectedRowKeysCart(cartSelect);
    setSelectedRowKeys([]);
    handleAddToCard(cartSelect);
  };

  const clickRowAddToCartEvt = (id: number) => {
    const temp = [...cartIds];
    temp.push(id);
    handleAddToCard([...new Set(temp)]);
  };

  const removeFromCartEvt = async (id: number) => {
    const currentCart = await findCart({ where: { id: cartId } });
    const items = JSON.parse(currentCart.items || '[]');
    const itemsAfterRemove = items.filter((item) => item !== id);
    setCartIds(itemsAfterRemove);
    const temp = cartItems.filter((item) => item.id !== id);
    setCartItems(temp);
    await patchCart(itemsAfterRemove, cartId);
  };

  const discardAll = async () => {
    setCartIds([]);
    setCartItems([]);
    setSelectedRowKeysCart([]);
    await patchCart([], cartId);
  };

  const unCheckAllCart = (): void => {
    setSelectedRowKeysCart([]);
  };

  const checkAllCart = (): void => {
    setSelectedRowKeysCart(cartIds);
  };

  const openCartModal = async (): Promise<void> => {
    let newCartIds: number[] = [];
    let items;
    if (!cartIds) {
      items = [];
    }
    if (cartIds.length > 99) {
      newCartIds = cartIds.slice(0, 100);
    } else {
      newCartIds = cartIds;
    }
    items = await getList({
      where: {
        id: {
          inq: newCartIds,
        },
      },
    });
    setCartItems(items);
    setCartIsModalOpen(true);
  };

  const pushSettingVisibleEvent = (
    visible: boolean,
    data?: { langs: string[]; portals: Portal[] }
  ) => {
    setShowPushSetting(visible);
  };

  const setPaginationEvtCart = (data: { page: number; pageSize: number }) => {
    setCurrentPageCart(data.page);
    setPageSize(data.pageSize);
  };

  const handleSelectedRowKeysCart = (keys: number[]) => {
    setSelectedRowKeysCart(keys);
  };

  const allowShuffleEvt = (state: boolean) => {
    filtersRef.current = {
      ...filtersRef.current,
      allowShuffle: state,
    };
    setAllowShuffle(state);
  };

  useEffect(() => {
    if (!selectedRowKeysCart.length) {
      return;
    }
    getList({
      where: {
        id: {
          inq: selectedRowKeysCart,
        },
      },
    }).then((result) => {
      setCartToPush(result);
    });
  }, [selectedRowKeysCart]);

  const PushSetting = () => {
    return (
      <PushSettings
        routeFrom="addCartPage"
        selectedContentHubs={cartToPush}
        dataPushEvent={dataPushEvent}
        isModalVisible={showPushSetting}
        portalList={portalList}
        modalVisibleEvent={pushSettingVisibleEvent}
      />
    );
  };

  const onRadioSelectedTagsChange = (e: RadioChangeEvent) => {
    setSelectCheckTagsCondition(e.target.value);
  };

  useEffect(() => {
    const userInfoString = jwtService.getUserInfo();
    const userInfo: Record<string, any> = JSON.parse(userInfoString || '{}');
    if (userInfo?.userId) {
      initCart(userInfo?.userId);
      setUserId(userInfo.userId);
    }

    mountedTrigger();

    fetchData().catch(console.error);

    fetchGender().catch(console.error);

    return () => {
      unMountedTrigger();
    };
  }, []);

  useEffect(() => {
    fetchContentHub().catch(console.error);
  }, [selectedNode, selectedGender, limitCategories, maxTimes]);

  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      selectedRowKeys,
    };
    const contentHubs = dataList.filter((e) => selectedRowKeys.includes(e.id));
    setSelectedContentHubs(contentHubs);
  }, [selectedRowKeys]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: `${listSelectedTags.length} tags`,
      children: (
        <CheckboxGroup
          options={listSelectedTags.map((tag) => tag.displayText)}
          onChange={onSelectedTagsChange}
        />
      ),
    },
  ];

  const exportExcelEvt = async () => {
    const data = dataList.filter((e) => selectedRowKeys.includes(e.id));
    if (!data?.length) {
      return;
    }
    setLoadingData(true);

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Content');

    worksheet.columns = [
      { header: '', key: 'ordinalNumber', width: 10 },
      { header: 'Content ID', key: 'content_id', width: 20 },
      { header: 'Slug', key: 'slug', width: 20 },
      { header: 'Image', key: 'image', width: 20 },
      { header: 'Category', key: 'category', width: 20 },
      { header: 'Gender', key: 'gender', width: 20 },
      { header: 'Tags', key: 'tags', width: 20 },
      { header: 'Used', key: 'used', width: 20 },
      { header: 'Used Count', key: 'used_count', width: 20 },
      { header: 'Title EN', key: 'title_en', width: 20 },
      { header: 'Excerpt EN', key: 'excerpt_en', width: 20 },
      { header: 'Description EN', key: 'description_en', width: 20 },
      { header: 'Title ES', key: 'title_es', width: 20 },
      { header: 'Excerpt ES', key: 'excerpt_es', width: 20 },
      { header: 'Description ES', key: 'description_es', width: 20 },
      { header: 'Title IT', key: 'title_it', width: 20 },
      { header: 'Excerpt IT', key: 'excerpt_it', width: 20 },
      { header: 'Description IT', key: 'description_it', width: 20 },
      { header: 'Title FR', key: 'title_fr', width: 20 },
      { header: 'Excerpt FR', key: 'excerpt_fr', width: 20 },
      { header: 'Description FR', key: 'description_fr', width: 20 },
      { header: 'Title CH', key: 'title_ch', width: 20 },
      { header: 'Excerpt CH', key: 'excerpt_ch', width: 20 },
      { header: 'Description CH', key: 'description_ch', width: 20 },
      { header: 'Title PT', key: 'title_pt', width: 20 },
      { header: 'Excerpt PT', key: 'excerpt_pt', width: 20 },
      { header: 'Description PT', key: 'description_pt', width: 20 },
    ];

    data.forEach((it: Record<string, any>, index: number) => {
      worksheet.addRow({ ...it, ordinalNumber: index + 1 });
    });

    await workbook.xlsx.writeBuffer().then((buffer: any) => {
      const date = getCurrentDateTime();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = `contents-export-${date}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });

    setLoadingData(false);
  };

  return (
    <div>
      <Row>
        <Col span={5}>
          {loading ? (
            <Skeleton loading={loading} active paragraph={{ rows: 6 }} />
          ) : (
            <Filters
              treeData={treeData}
              callBack={callBack}
              genderOptions={genderOptions}
              categories={cateList}
              clearFilterEvent={clearFilterEvent}
              treeStateChangeEvent={treeStateChangeEvent}
              allowShuffle={allowShuffle}
              allowShuffleEvt={allowShuffleEvt}
              parentCategoriesCheckedStateEvt={parentCategoriesCheckedStateEvt}
            />
          )}
        </Col>
        <Col span={19}>
          <CartStyle>
            <Badge count={cartIds.length}>
              <Button type="primary" onClick={openCartModal}>
                View Cart
              </Button>
            </Badge>
          </CartStyle>

          {portalList?.length && <PushSetting />}

          <HeaderButtons
            portalList={portalList}
            selectedContentHubs={selectedContentHubs}
            dataPushEvent={dataPushEvent}
            viewListEvent={viewListEvent}
            viewTableEvent={viewTableEvent}
            uncheckAllEvent={uncheckAllEvent}
            selectAllEvent={selectAllEvent}
            editContentHub={editContentHubEvent}
            addToCartEvt={addToCartEvt}
            filterByTag={filterByTagEvt}
            exportExcel={exportExcelEvt}
          />
          <SelectedTagsStyle>
            <span>List Selected Tags: </span>

            <Radio.Group onChange={onRadioSelectedTagsChange} value={selectCheckTagsCondition}>
              <Radio value={'AND'}>AND</Radio>
              <Radio value={'OR'}>OR</Radio>
            </Radio.Group>

            <Collapse items={items} defaultActiveKey={['1']} />
          </SelectedTagsStyle>

          <p style={{ marginLeft: '10px' }}>
            Total Selected: <b>{selectedRowKeys.length}</b> posts
          </p>
          {toggleView.type === 'list' ? (
            <CardViewList
              data={dataList}
              page={currentPage}
              total={total}
              pageSize={pageSize}
              selectedRowKeys={selectedRowKeys}
              setPagination={setPaginationEvt}
              setSelectedRowKeys={handleSelectedRowKeys}
              viewDetailEvent={viewDetailEvent}
            />
          ) : (
            <Spin spinning={loadingData} size="large" tip="Loading...">
              <ListView
                type="view_and_push"
                portalList={portalList}
                companyList={companyList}
                data={dataList}
                page={currentPage}
                total={total}
                setTotal={setTotal}
                pageSize={pageSize}
                selectedRowKeys={selectedRowKeys}
                setPagination={setPaginationEvt}
                setSelectedRowKeys={handleSelectedRowKeys}
                searchText={searchText}
                setSearchText={setSearchText}
                searchedColumn={searchedColumn}
                setSearchedColumn={setSearchedColumn}
                viewDetailEvent={viewDetailEvent}
                addToCartEvt={clickRowAddToCartEvt}
              />
            </Spin>
          )}
        </Col>
      </Row>
      {showEditDialog && (
        <EditTableCell
          showVisible={showEditDialog}
          data={selectedContentHubs[0]}
          showVisibleEvent={showEditDialogEvent}
          portalList={portalList}
          companyList={companyList}
        />
      )}
      {isCartModalOpen && (
        <CartModal
          pageSize={pageSize}
          currentPageCart={currentPageCart}
          cartItems={cartItems}
          isCartModalOpen={isCartModalOpen}
          selectedRowKeysCart={selectedRowKeysCart}
          discardAllEvt={discardAll}
          checkAllCartEvt={checkAllCart}
          unCheckAllCartEvt={unCheckAllCart}
          viewDetailEvent={viewDetailEvent}
          removeFromCartEvt={removeFromCartEvt}
          handleSelectedRowKeysCart={handleSelectedRowKeysCart}
          setPaginationEvtCart={setPaginationEvtCart}
          setCartIsModalOpenEvt={setCartIsModalOpen}
          setShowPushSettingEvt={setShowPushSetting}
        />
      )}
    </div>
  );
};

export default ViewAndPush;
